export const passwordConstants = {
  Change: {
    REQUEST: "CHANGE_PASSWORD_REQUEST",
    SUCCESS: "CHANGE_PASSWORD_SUCCESS",
    FAILURE: "CHANGE_PASSWORD_FAILURE",
  },
  Restore: {
    REQUEST: "RESTORE_PASSWORD_REQUEST",
    SUCCESS: "RESTORE_PASSWORD_SUCCESS",
    FAILURE: "RESTORE_PASSWORD_FAILURE",
  },
  Reset: {
    REQUEST: "RESET_PASSWORD_REQUEST",
    SUCCESS: "RESET_PASSWORD_SUCCESS",
    FAILURE: "RESET_PASSWORD_FAILURE",
    CLEAR: "CLEAR_PASSWORD"
  },
};
