export const i18n = {
    buttonAdd: {
        1: "Добавить подписку",
        0: "Дадаць падпіску",
    },
    buttonDelete: {
        1: "Удалить",
        0: "Выдаліць",
    },
    buttonCancel: {
        1: "Отмена",
        0: "Адмена",
    },
    messageItemAddSuccses: {
        1: "Подписка успешно добавлена",
        0: "Падпіска паспяхова дададзеная",
    },
    messageItemDeleteSuccses: {
        1: "Подписка успешно удалена",
        0: "Падпіска паспяхова выдаленая",
    },
    fullNameAisMv: {
        1: "Название организации в СМДО",
        0: "Назва арганізацыі ў СМДА",
    },
    fullNameInSystem: {
        1: "Название организации в системе",
        0: "Назва арганізацыі ў сістэме",
    },
    smdoCode: {
        1: "Код СМДО",
        0: "Код СМДО",
    },
    messageChooiseDeleteItem: {
        1: "Вы уверены, что хотите удалить данную запись?",
        0: "Вы ўпэўненыя, што жадаеце выдаліць дадзены запіс?",
    },
    placeholderSearchInput: {
        1: "Код СМДО | Название (Минимальная длина 3 символа)",
        0: "Код СМДО | Назва (Мінімальная даўжыня 3 сімвала)",
    },
    placeholderSearchOrgInSystem: {
        1: "УНП | Название (Минимальная длина 3 символа)",
        0: "УНП | Назва (Мінімальная даўжыня 3 сімвала)",
    },
    deleteCol: {
        1:"Удалить подписку",
        0:"Выдаліць падпіску"
    },
    isRequired: {
        1:"Данное поле обязательно для заполнения",
        0:"Гэта поле абавязкова для запаўнення"
    },
    successCreate: {
        1:"Подписка успешно добавлена",
        0:"Падпіска паспяхова дададзена",
    },
    successDelete: {
        1:"Подписка успешно удалена",
        0:"Падпіска паспяхова выдалена",
    },
    closeButton: {
        1:"Закрыть",
        0:"Закрыць",
    },
}
export default i18n