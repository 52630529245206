import {
	messageConstants
}from "./constants"
import {
	defAction
}from "../../../helpers/defaultAction"
import api from "./api"

export const messageActions = {
	changeMessageChat,
	clear,
	deleteMessage,
	downloadMessageFile,
	findMessages,
	getChats,
	getMessages,
	sendMessage,
	setRedirectUser
}

function sendMessage(values){
	const dispatchObj = {
		constants : messageConstants.sendMessage,
		service   : {
			func   : api.sendMessage,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getChats(values){
	const dispatchObj = {
		constants : messageConstants.getChats,
		service   : {
			func   : api.getChats,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function getMessages(values){
	const dispatchObj = {
		constants : messageConstants.getMessages,
		service   : {
			func   : api.getMessages,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function findMessages(values){
	const dispatchObj = {
		constants : messageConstants.findMessages,
		service   : {
			func   : api.findMessages,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function downloadMessageFile(values){
	const dispatchObj = {
		constants : messageConstants.downloadFile,
		service   : {
			func   : api.downloadMessageFile,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function deleteMessage(values){
	const dispatchObj = {
		constants : messageConstants.deleteMessage,
		service   : {
			func   : api.deleteMessage,
			params : values
		}
	}
	return defAction(dispatchObj)
}

function clear(){
	return (dispatch) => {
		dispatch(request())
		function request(){
			return {
				type: messageConstants.sendMessage.CLEAR
			}
		}
	}
}

function setRedirectUser(params){
	return (dispatch) => {
		dispatch(request())
		function request(){
			return {
				type    : messageConstants.getChats.REDIRECT,
				payload : params
			}
		}
	}
}

function changeMessageChat(value){
	const dispatchObj = {
		constants : messageConstants.changeMessageChat,
		service   : {
			func   : api.changeMessageChat,
			params : value
		}
	}
	return defAction(dispatchObj)
}
