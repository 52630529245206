const i18n = {
	appealRegNumber: {
		0 : "Рэгістрацыйны нумар звароту",
		1 : "Регистрационный номер обращения"
	},
	createCreateDisciplinaryAction: {
		0 : "Прыцягнуць да адказнасці",
		1 : "Привлечь к ответственности"
	},
	creatorName: {
		0 : "Імя стваральніка",
		1 : "Имя создателя"
	},
	isRequired: {
		0 : "Поле абавязкова для запаўнення.",
		1 : "Поле обязательно для заполнения."
	},
	labelAppeal: {
		0 : "Нумар звароту",
		1 : "Номер обращения"
	},
	labelResponsibilityType: {
		0 : 'Выгляд адказнасці:',
		1 : 'Вид ответственности:'
	},
	placeholderAppeal: {
		0 : "Увядзіце нумар звароту",
		1 : "Введите номер обращения"
	},
	placeholderResponsibilityType: {
		0 : 'Пачніце ўводзіць назву выгляду адказнасці',
		1 : 'Начните вводить название вида ответственности'
	},
	reResponsibilityTypeName: {
		0 : "Выгляд адказнасці",
		1 : "Вид ответственности"
	},
	removeDisciplinaryAction: {
		0 : "Выдаліць парушэнне?",
		1 : "Удалить нарушение?"
	},
	successCreateDisciplinaryAction: {
		0 : "Дзеянне прайшло паспяхова!",
		1 : "Действие прошло успешно!"
	}
}

export default i18n
