import React from "react";

const i18n = {
  logoTextH: {
    1: "Система обращений",
    0: "Сістэма зваротаў",
  },
  logoText: {
    1: <span>Система обращений</span>,
    0: <span>Сістэма зваротаў</span>,
  },
  footerItems: {
    1: ["Новости", "Контакты", "Помощь", "НСИ"],
    0: ["Навіны", "Кантакты", "Дапамога", "НДІ"],
  },

  menuItems: {
    1: ["Главная", "Вход"],
    0: ["Галоўная", "Уваход"],
  },

  menuItemsAuth: {
    1: ["Главная"],
    0: ["Галоўная"],
  },

  acSubmenuText: {
    1: ["Личный кабинет", "Выход"],
    0: ["Асабісты кабінет", "Выхад"],
  },
  headerTextBox: {
    1: [
      "Подача, приём, обработка и учёт обращений физических и юридических лиц",
      "в государственные и иные организации",
    ],
    0: [
      "Падача, прыём, апрацоўка і ўлік",
      "зваротаў фізічных і юрыдычных асоб у дзяржаўныя і іншыя арганізацыі",
    ],
  },
  btnSubmitAppeal: {
    1: "Подать обращение",
    0: "Падаць зварот",
  },
  btnReg: {
    1: "Зарегистрироваться",
    0: "Зарэгістравацца",
  },
  btnLogin: {
    1: "Войти в систему",
    0: "Увайсці ў сістэму",
  },
  logoutTimer: {
    1: "Таймер до истечения сессии",
    0: "Таймер да заканчэння сесіі"
  },
  sessionExtensionTitle: {
    1: "Вам нужно больше времени? Желаете продлить сессию?",
    0: "Вам трэба больш часу? Жадаеце падоўжыць сесію?"
  },
  sessionExtensionBtn: {
    1: "Продлить сессию",
    0: "Падоўжыць сесію"
  }
};
export default i18n;
