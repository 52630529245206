export const  newsConstants = {
    
    getNews: {
      REQUEST: "GET_NEWS_REQUEST",
      SUCCESS: "GET_NEWS_SUCCESS",
      FAILURE: "GET_NEWS_FAILURE",
    },
    getOneNews: {
        REQUEST: "GETONE_NEWS_REQUEST",
        SUCCESS: "GETONE_NEWS_SUCCESS",
        FAILURE: "GETONE_NEWS_FAILURE",
    },
    createNews: {
        REQUEST: 'CREATE_NEWS_REQUEST',
        SUCCESS: 'CREATE_NEWS_SUCCESS',
        FAILURE: 'CREATE_NEWS_FAILURE',
    },
    deleteNews: {
        REQUEST: 'DELETE_NEWS_REQUEST',
        SUCCESS: 'DELETE_NEWS_SUCCESS',
        FAILURE: 'DELETE_NEWS_FAILURE',
    },
    changeNews: {
        REQUEST: 'CHANGE_NEWS_REQUEST',
        SUCCESS: 'CHANGE_NEWS_SUCCESS',
        FAILURE: 'CHANGE_NEWS_FAILURE',
    },
    downloadFile: {
        REQUEST: "GET_FILE_REQUEST",
        SUCCESS: "GET_FILE_SUCCESS",
        FAILURE: "GET_FILE_FAILURE",
    },
    sendFile: {
        REQUEST: 'SEND_FILE_REQUEST',
        SUCCESS: 'SEND_FILE_SUCCESS',
        FAILURE: 'SEND_FILE_FAILURE',
    },
    sendFiles: {
        REQUEST: 'SEND_FILES_REQUEST',
        SUCCESS: 'SEND_FILES_SUCCESS',
        FAILURE: 'SEND_FILES_FAILURE',
    },
  };