export const emailConstants = {
  ChangeEmail: {
    REQUEST: "CHANGE_EMAIL_REQUEST",
    SUCCESS: "CHANGE_EMAIL_SUCCESS",
    FAILURE: "CHANGE_EMAIL_FAILURE",
  },
  ConfirmEmail: {
    REQUEST: "CONFIRM_EMAIL_REQUEST",
    SUCCESS: "CONFIRM_EMAIL_SUCCESS",
    FAILURE: "CONFIRM_EMAIL_FAILURE",
  },
  RepeatConfirmRequest: {
    REQUEST: "REPEAT_CONFIRM_EMAIL_REQUEST",
    SUCCESS: "REPEAT_CONFIRM_EMAIL_SUCCESS",
    FAILURE: "REPEAT_CONFIRM_EMAIL_FAILURE",
  }
};
