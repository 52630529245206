import {
	emailConstants
}from "./constants"
import notice from "../../../../components/Notice"
import isRu from "../../../../helpers/isRu"
import i18n from "../../localization"

const initialState = {
	changeEmailLoading   : false,
	isEmailChanged       : null,
	confirmEmailLoading  : false,
	isEmailConfirmed     : null,
	confirmEmailError    : null,
	repeatRequestLoading : false
}

export default function (state = initialState, action){
	switch (action.type){
		case emailConstants.ChangeEmail.REQUEST:
			return {
				...state,
				changeEmailLoading : true,
				isEmailChanged     : null
			}
		case emailConstants.ChangeEmail.SUCCESS:
			notice("success", i18n.changeEmailSuccessMessage[isRu()])
			return {
				...state,
				changeEmailLoading : false,
				isEmailChanged     : true
			}
		case emailConstants.ChangeEmail.FAILURE:
			const error = action.payload.err.data

			//notice("error", error.errors);
			return {
				...state,
				changeEmailLoading : false,
				isEmailChanged     : false,
				error              : error
			}

			//Confirm
		case emailConstants.ConfirmEmail.REQUEST:
			return {
				...state,
				confirmEmailLoading : true,
				isEmailConfirmed    : null,
				confirmEmailError   : null
			}
		case emailConstants.ConfirmEmail.SUCCESS:
			return {
				...state,
				confirmEmailLoading : false,
				isEmailConfirmed    : true
			}
		case emailConstants.ConfirmEmail.FAILURE:
			return {
				...state,
				confirmEmailLoading : false,
				isEmailConfirmed    : false,
				confirmEmailError   : action.payload.err.data
			}

			//RepeatConfirmRequest
		case emailConstants.RepeatConfirmRequest.REQUEST:
			return {
				...state,
				repeatRequestLoading: true
			}
		case emailConstants.RepeatConfirmRequest.SUCCESS:
			notice("success", i18n.repeatChangeEmailMessage[isRu()])
			return {
				...state,
				repeatRequestLoading: false
			}
		case emailConstants.RepeatConfirmRequest.FAILURE:
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				repeatRequestLoading: false
			}
		default:
			return state
	}
}
