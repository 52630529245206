import React from "react"

const i18n = {
	validateMessages: {
		required: {
			1 : "Поле '${label}' обязательно для заполнения",
			0 : "Поле '${label}' абавязкова для запаўнення"
		}
	},
	restoreHeader: {
		1 : "Восстановление пароля",
		0 : "Аднаўленне пароля"
	},
	position: {
		1 : "Должность",
		0 : "Пасада"
	},
	restoreDescription: {
		1 : "Пожалуйста, укажите номер телефон, который Вы использовали для входа на сайт.",
		0 : "Калі ласка, пакажыце нумар тэлефон, які вы выкарыстоўвалі для ўваходу на сайт."
	},
	mobileLength: {
		1 : "Длина поля 8-20 символов.",
		0 : "Даўжыня поля 8-20 знакаў."
	},
	mobileMessage: {
		1 : "Номер телефона заполнен некорректно.",
		0 : "Нумар тэлефона запоўнен некарэктна."
	},
	passwordMessage: {
		1 : "Пароль должен содержать минимум 8 символов, содержать латинские символы в верхнем и нижнем регистре, а также минимум один цифровой символ.",
		0 : "Пароль павінен змяшчаць мінімум 8 сімвалаў, утрымліваць лацінскія сімвалы ў верхнім і ніжнім рэгістры, а таксама мінімум адзін лічбавы сімвал."
	},
	passwordHelp: {
		1 : "Пароль должен содержать не менее 8 символов",
		0 : "Пароль павінен змяшчаць не менш за 8 сімвалаў"
	},
	confirmPassword: {
		1 : "Подтверждение пароля",
		0 : "Пацверджанне пароля"
	},
	passwordEquals: {
		1 : "Пароли должны совпадать.",
		0 : "Паролі павінны супадаць."
	},
	newPasswordHeader: {
		1 : "Новый пароль",
		0 : "Новы пароль"
	},
	newPassword: {
		1 : ["Введите новый пароль", "Повторите новый пароль"],
		0 : ["Увядзіце новы пароль", "Паўтарыце новы пароль"]
	},
	nextHeader: {
		1 : "Далее",
		0 : "Далей"
	},
	save: {
		1 : "Cохранить",
		0 : "Захаваць"
	},
	profile: {
		1 : "Профиль пользователя",
		0 : "Профіль карыстальніка"
	},
	ecp: {
		1 : "ЭЦП",
		0 : "ЭЛП"
	},
	notices: {
		1 : "Настройка уведомлений",
		0 : "Налад апавяшчэнняў"
	},
	password: {
		1 : "Пароль",
		0 : "Пароль"
	},
	userHistory: {
		1 : "История изменений данных",
		0 : "Гісторыя змяненняў даных"
	},
	sessions: {
		1 : "Активные сессии",
		0 : "Актыўныя сесіі"
	},
	common: {
		1 : "Общее",
		0 : "Агульнае"
	},
	organization: {
		1 : "Организация",
		0 : "Арганізацыя"
	},
	confirmed: {
		1 : "Подтвержден",
		0 : "Пацверджаны"
	},
	notConfirmed: {
		1 : "Не подтвержден",
		0 : "Не пацверджаны"
	},
	incorrectEmail: {
		1: "Поле заполнено некорректно."
	},
	sameEmail: {
		1 : "Новый Email совпадает с предыдущим.",
		0 : "Новы Email супадае з папярэднім."
	},
	changeEmail: {
		1 : "Изменить E-mail",
		0 : "Змяніць E-mail"
	},
	changePassword: {
		1 : "Изменить пароль",
		0 : "Змяніць пароль"
	},
	confirmEmail: {
		1 : "Подтверждение электронной почты пользователя",
		0 : "Пацверджанне электроннай пошты карыстальніка"
	},
	progress: {
		1 : "Идет проверка данных",
		0 : "Ідзе праверка дадзеных"
	},
	sucessConfirmEmail: {
		1 : "Подтверждение Email прошло успешно!",
		0 : "Пацверджанне Email прайшло паспяхова!"
	},
	authoriseToConfirm: {
		1 : "Для подтверждения Email необходимо авторизоваться в системе!",
		0 : "Для пацверджання Email неабходна аўтарызавацца ў сістэме!"
	},
	newConfirmEmailMessage: {
		1 : "Подтвердить E-mail",
		0 : "Пацвердзіць E-mail"
	},
	oldPassword: {
		1 : "Старый пароль",
		0 : "Стары пароль"
	},
	successSessionDelete: {
		1 : "Завершение сессии прошло успешно!",
		0 : "Завяршэнне сесіі прайшло паспяхова!"
	},
	confirmSessionDelete: {
		1 : "Вы действительно хотите завершить выбранную сессию?",
		0 : "Вы сапраўды хочаце завяршыць выбраную сесію?"
	},
	deleteSession: {
		1 : "Завершить сеанс",
		0 : "Завяршыць сеанс"
	},
	lastTime: {
		1 : "Последний сеанс",
		0 : "Завяршыць сеанс"
	},
	currentSession: {
		1 : "Текущий сеанс",
		0 : "Дадзены сеанс"
	},
	changeEmailSuccessMessage: {
		1 : "Изменение Email произошло успешно! На указанный Email в течение 5 минут придет письмо для подтверждением!",
		0 : "Змена Email адбылося паспяхова! На ўказаны Email на працягу 5 хвілін прыйдзе ліст для пацвярджэннем!"
	},
	repeatChangeEmailMessage: {
		1 : "Повторное сообщение для подтверждения успешно отправлено!",
		0 : "Паўторнае паведамленне для пацверджання паспяхова адпраўлена!"
	},
	changePasswordSuccessMessage: {
		1 : "Изменение пароля произошло успешно!",
		0 : "Змена пароля адбылося паспяхова!"
	},
	successChangePhone: {
		1 : "Номер успешно изменен!",
		0 : "Нумар паспяхова зменены!"
	},
	changePhone: {
		1 : "Изменить номер",
		0 : "Змяніць нумар"
	},
	submitButton: {
		1 : "Сохранить изменения",
		0 : "Захаваць змены"
	},
	phone: {
		1 : "Номер телефона",
		0 : "Нумар тэлефона"
	},
	phoneNumb: {
		1 : "Введите Ваш номер телефона",
		0 : "Увядзіце ваш нумар тэлефона"
	},
	confirmCode: {
		1 : "Отправить код",
		0 : "Даслаць код"
	},
	noChanges: {
		1 : "Нет изменений.",
		0 : "Няма зменаў."
	},
	userType: {
		1 : "Тип пользователя",
		0 : "Тып карыстальніка"
	},
	userName: {
		1 : 'Имя пользователя (логин)',
		0 : 'Імя карыстальніка (лагін)'
	},
	isAdmin: {
		1 : "Администратор системы",
		0 : "Адміністратар сістэмы"
	},
	backToAccount: {
		1 : "Назад в личный кабинет",
		0 : "Назад ў асабісты кабінет"
	},
	signatureLabel: {
		1 : "Идентификатор личного ключа пользователя",
		0 : "Ідэнтыфікатар асабістага ключа карыстальніка"
	},
	addSignatureToAccount: {
		1 : "Прикрепить через ключ ЭЦП ГосСУОК",
		0 : "Прымацаваць праз ключ ЭЛП ДзяржСУАК"
	},
	addSignatureError: {
		1 : "ЭЦП не была прикреплена.",
		0 : "ЭЛП не была прымацавана."
	},
	signatureInvalidBrowserError: {
		1 : "Данный браузер не поддерживает работу с ЭЦП.",
		0 : "Дадзены браўзер не падтрымлівае працу з ЭЛП."
	},
	signatureInvalidUnpError: {
		1 : "УНП пользователя не совпадает с УНП на цифровом носителе.",
		0 : "УНП карыстальніка не супадае з УНП на лічбавым носьбіце."
	},
	idCardLabel: {
		1 : "Идентификатор ID-карты пользователя",
		0 : "Ідэнтыфікатар ID-карты карыстальніка"
	},
	addIdCard: {
		1 : "Прикрепить через ID-карту",
		0 : "Прымацаваць праз ID-карту"
	},
	IdentificationNumberLabel: {
		1 : "ЭЦП/ID-карта",
		0 : "ЭЛП/ID-карта"
	},
	IdentificationNumberLabelHelper: {
		1 : "Идентификационный номер гражданина Республики Беларусь. Используется для авторизации в системе через ключ ЭЦП ГосСУОК, ЕС ИФЮЛ и НПЭС.",
		0 : "Ідэнтыфікацыйны нумар грамадзяніна Рэспублікі Беларусь. Выкарыстоўваецца для аўтарызацыі ў сістэме праз ключ ЭЛП ДзяржСУАК, АС ІФЮА і НПЭС."
	},
	errorWithTextSignInCP: {
		1 : 'Ошибка прикрепления, код ошибки ',
		0 : 'Памылка прымацавання, код памылкі '
	},
	errorWithCPAccess: {
		1 : 'Ошибка доступа клиентского приложения. Проверьте, запущена ли у вас программа.',
		0 : 'Памылка доступу кліенцкага прыкладання. Праверце, ці запушчана ў вас праграма.'
	},
	attachIdNumberToAccount: {
		0 : "Прымацаваць",
		1 : "Прикрепить"
	},
	detachIdNumberFromAccount: {
		0 : "Адмацаваць",
		1 : "Открепить"
	},
	detachIdNumberSuccess: {
		0 : "ЭЛП/ID-карта паспяхова адмацавана",
		1 : "ЭЦП/ID-карта успешно откреплена"
	},
	errorOccurred: {
		0 : "Адбылася памылка",
		1 : "Произошла ошибка"
	}
}

export default i18n
