import { notificationSettingsConstants } from "./constants";

const initialState = {
  //get;
  getLoading: false,
  notificationSettings: null,
  getError: null,
  //set;
  setLoading: false,
  setSuccess: null,
  setError: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    //get;
    case notificationSettingsConstants.Get.REQUEST:
      return {
        ...state,
        getLoading: true,
        setSuccess: null,
        notificationSettings: null,
        getError: null,
      };
    case notificationSettingsConstants.Get.SUCCESS:
      return {
        ...state,
        getLoading: false,
        notificationSettings: action.payload.result,
      };
    case notificationSettingsConstants.Get.FAILURE:
      return {
        ...state,
        getLoading: false,
        getError: action.payload.err.data.errors,
      };
    //set;
    case notificationSettingsConstants.Set.REQUEST:
      return {
        ...state,
        setLoading: true,
        setSuccess: null,
        setError: null,
      };
    case notificationSettingsConstants.Set.SUCCESS:
      return {
        ...state,
        setLoading: false,
        setSuccess: action.payload.result,
      };
    case notificationSettingsConstants.Set.FAILURE:
      return {
        ...state,
        setLoading: false,
        setError: action.payload.err.data.errors,
      };
    default:
      return state;
  }
}
