export const MessageToAdminConstants = {

	GetItems: {
		REQUEST : "GET_MESSAGE_REQUEST",
		SUCCESS : "GET_MESSAGE_SUCCESS",
		FAILURE : "GET_MESSAGE_FAILURE"
	},
	SetMessage: {
		REQUEST : 'SET_MESSAGE_REQUEST',
		SUCCESS : 'SET_MESSAGE_SUCCESS',
		FAILURE : 'SET_MESSAGE_FAILURE'
	},
	adminLookedMessage: {
		REQUEST : 'ADMIN_LOOKED_MESSAGE_REQUEST',
		SUCCESS : 'ADMIN_LOOKED_MESSAGE_SUCCESS',
		FAILURE : 'ADMIN_LOOKED_MESSAGE_FAILURE'
	},
	sendMessageToSpam: {
		REQUEST : 'ADMIN_SEND_MESSAGE_TO_SPAM_REQUEST',
		SUCCESS : 'ADMIN_SEND_MESSAGE_TO_SPAM_SUCCESS',
		FAILURE : 'ADMIN_SEND_MESSAGE_TO_SPAM_FAILURE'
	},
	sendMessagesListToSpam: {
		REQUEST : 'ADMIN_SEND_MESSAGES_LIST_TO_SPAM_REQUEST',
		SUCCESS : 'ADMIN_SEND_MESSAGES_LIST_TO_SPAM_SUCCESS',
		FAILURE : 'ADMIN_SEND_MESSAGES_LIST_TO_SPAM_FAILURE'
	}
}