import {
  aisMvHandleRegistrationConstants
} from './constants'
import api from './api';
import { defAction } from '../../../helpers/defaultAction';
import {reOrgsConstants} from "../../nsi/ReOrgs/constants";

export const aisMvHandleRegistrationActions = {
  getAisMvExistSubscribers,
  getAisMvBySearch,
  createSubscribeAisMv,
  deleteSubscribeAisMv,
}

function getAisMvExistSubscribers(params){
  const dispatchObj = {
    constants : aisMvHandleRegistrationConstants.getAisMvExistSubscribers,
    service   : {
      func   : api.getAisMvExistSubscribers,
      params : params
    }
  }

  return defAction(dispatchObj)
}

function getAisMvBySearch(params){
  const dispatchObj = {
    constants : aisMvHandleRegistrationConstants.getAisMvBySearch,
    service   : {
      func   : api.getAisMvBySearch,
      params : params
    }
  }

  return defAction(dispatchObj)
}

function createSubscribeAisMv(value) {
  const dispatchObj = {
    constants: aisMvHandleRegistrationConstants.createSubscribeAisMv,
    service: { 
      func: api.createSubscribeAisMv,
      params: value 
    }
  }

  return defAction(dispatchObj)
}
function deleteSubscribeAisMv(value) {
  const dispatchObj = {
    constants: aisMvHandleRegistrationConstants.deleteSubscribeAisMv,
    service: {
      func: api.deleteSubscribeAisMv,
      params: value
    }
  }

  return defAction(dispatchObj)
}

