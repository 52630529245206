import {
	uploadJsonFileConstants
}from "./constants"
import downloadFile from "../../helpers/downloadFile"
import notice from "../../components/Notice"
import moment from "moment"

const initialState =
{
	downloadedNRIFile    : null,
	unloadedAppealsFile  : null,
	startAppealsDownload : false,
	isSending            : false,
	error                : null,
	result               : null,
	startNRIDownload     : false,
	startNRIDownloadV2   : false,
	uploadUsersLoading   : false,
	uploadUsersResult    : null,
	uploadOrgsLoading    : false,
	uploadOrgsResult     : null
}

export default function (state = initialState, action){
	switch (action.type){
		case uploadJsonFileConstants.UploadJsonFiles.REQUEST :
			return {
				...state,
				isSending : true,
				result    : null
			}
		case uploadJsonFileConstants.UploadJsonFiles.SUCCESS :
			return {
				...state,
				isSending : false,
				result    : action.payload.result
			}
		case uploadJsonFileConstants.UploadJsonFiles.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				isSending : false,
				error     : action.error
			}
		case uploadJsonFileConstants.DownloadNRIFile.REQUEST :
			return {
				...state,

				//downloadedNRIFile : null,
				startNRIDownload: true
			}
		case uploadJsonFileConstants.DownloadNRIFile.SUCCESS :
			downloadFile(action.payload.result, { fileName      : `NRI-${ moment(new Date()).format("DD.MM.YYYY") }`,
				fileExtension : ".zip" })
			return {
				...state,

				//downloadedNRIFile : action.payload.result,
				startNRIDownload: false
			}
		case uploadJsonFileConstants.DownloadNRIFile.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,

				//downloadedNRIFile : null,
				startNRIDownload: false
			}
		case uploadJsonFileConstants.DownloadNRIFileV2.REQUEST :
			return {
				...state,
				startNRIDownloadV2: true
			}
		case uploadJsonFileConstants.DownloadNRIFileV2.SUCCESS :
			downloadFile(action.payload.result, { fileName      : `NRI_V2-${ moment(new Date()).format("DD.MM.YYYY") }`,
				fileExtension : ".zip" })

			return {
				...state,
				startNRIDownloadV2: false
			}
		case uploadJsonFileConstants.DownloadNRIFileV2.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				startNRIDownloadV2: false
			}
		case uploadJsonFileConstants.UnloadAppealsJson.REQUEST :
			return {
				...state,
				unloadedAppealsFile  : null,
				startAppealsDownload : true
			}
		case uploadJsonFileConstants.UnloadAppealsJson.SUCCESS :
			return {
				...state,
				unloadedAppealsFile  : action.payload.result,
				startAppealsDownload : false
			}
		case uploadJsonFileConstants.UnloadAppealsJson.FAILURE :
			return {
				...state,
				unloadedAppealsFile  : null,
				startAppealsDownload : false
			}
		case uploadJsonFileConstants.UploadUsersFromFile.REQUEST :
			return {
				...state,
				uploadUsersResult  : null,
				uploadUsersLoading : true
			}
		case uploadJsonFileConstants.UploadUsersFromFile.SUCCESS :
			return {
				...state,
				uploadUsersResult  : action.payload.result,
				uploadUsersLoading : false
			}
		case uploadJsonFileConstants.UploadUsersFromFile.FAILURE :
			return {
				...state,
				uploadUsersResult  : null,
				uploadUsersLoading : false
			}
		case uploadJsonFileConstants.UploadOrgsFromFile.REQUEST :
			return {
				...state,
				uploadOrgsResult  : null,
				uploadOrgsLoading : true
			}
		case uploadJsonFileConstants.UploadOrgsFromFile.SUCCESS :
			return {
				...state,
				uploadOrgsResult  : action.payload.result,
				uploadOrgsLoading : false
			}
		case uploadJsonFileConstants.UploadOrgsFromFile.FAILURE :
			return {
				...state,
				uploadOrgsResult  : null,
				uploadOrgsLoading : false
			}
		case uploadJsonFileConstants.ClearFiles.CLEAR :
			return {
				...state,
				startAppealsDownload : false,
				startNRIDownload     : false,
				unloadedAppealsFile  : null,
				downloadedNRIFile    : null,
				result               : null,
				uploadUsersLoading   : false,
				uploadUsersResult    : null,
				uploadOrgsLoading    : false,
				uploadOrgsResult     : null
			}
		default :
			return state
	}
}