export const AisMvManageConstant = {
	ChangeSubscribeAisMv: {
		FAILURE : 'CHANGE_SUBSCRIBE_CONTROL_FAILURE',
		REQUEST : 'CHANGE_SUBSCRIBE_CONTROL_REQUEST',
		SUCCESS : 'CHANGE_SUBSCRIBE_CONTROL_SUCCESS'
	},
	CheckSubscribeAisMv: {
		FAILURE : 'CHECK_SUBSCRIBE_CONTROL_FAILURE',
		REQUEST : 'CHECK_SUBSCRIBE_CONTROL_REQUEST',
		SUCCESS : 'CHECK_SUBSCRIBE_CONTROL_SUCCESS'
	},
	DownloadAisMVFile: {
		FAILURE : 'DOWNLOAD_AISMV_FILE_FAILURE',
		REQUEST : 'DOWNLOAD_AISMV_FILE_REQUEST',
		SUCCESS : 'DOWNLOAD_AISMV_FILE_SUCCESS',
		СLEAR   : "DOWNLOAD_AISMV_FILE_CLEAR"
	},
	GetAisMVMessageHistory: {
		FAILURE : 'GET_MESSAGES_HISTORY_FAILURE',
		REQUEST : 'GET_MESSAGES_HISTORY_REQUEST',
		SUCCESS : 'GET_MESSAGES_HISTORY_SUCCESS'
	},
	GetAppealAisMvMessageFiles: {
		FAILURE : 'GET_APPEAL_MESSAGE_FILES_REQUEST',
		REQUEST : 'GET_APPEAL_MESSAGE_FILES_FAILURE',
		SUCCESS : 'GET_APPEAL_MESSAGE_FILES_SUCCESS'
	},
	GetMessageBody: {
		FAILURE : 'GET_MESSAGE_BODY_FAILURE',
		REQUEST : 'GET_MESSAGE_BODY_REQUEST',
		SUCCESS : 'GET_MESSAGE_BODY_SUCCESS'
	},
	GetMessages: {
		FAILURE : 'GET_MESSAGES_FAILURE',
		REQUEST : 'GET_MESSAGES_REQUEST',
		SUCCESS : 'GET_MESSAGES_SUCCESS'
	},
	GetSubscribersBySearch: {
		FAILURE : 'GET_SUBSCRIBER_BY_SEARCH_FAILURE',
		REQUEST : 'GET_SUBSCRIBER_BY_SEARCH_REQUEST',
		SUCCESS : 'GET_SUBSCRIBER_BY_SEARCH_SUCCESS'
	}

}