const i18n = {
  blocked: {
    1: "Заблокирован",
    0: "Заблакаваны",
  },
  notBlocked: {
    1: "Не заблокирован",
    0: "Не заблякаваны",
  },
  status: {
    1: "Статус",
    0: "Статус",
  },
  block: {
    1: "Заблокировать пользователя",
    0: "Заблакаваць карыстальніка",
  },
  unblock: {
    1: "Разблокировать пользователя",
    0: "Разблакіраваць карыстальніка",
  },
  blockDate: {
    1: "Дата разблокировки",
    0: "Дата разблакоўкі",
  },
  confirm: {
    1: "Вы действительно хотите {0} пользователя?",
    0: "Вы сапраўды хочаце {0} карыстальніка?",
  },
  successBlock: {
    1: "Пользователь успешно заблокирован!",
    0: "Карыстальнік паспяхова заблякаваны!",
  },
  successUnblock: {
    1: "Пользователь успешно разблокирован!",
    0: "Карыстальнік паспяхова адмыкнуты!",
  }
};

export default i18n;
