import {aisMvHandleRegistrationActions} from "./actions";

export const aisMvHandleRegistrationConstants = {
  getAisMvExistSubscribers:{
    REQUEST: 'SEND_AISMVHANDREGIST_REQUEST',
    SUCCESS: 'SEND_AISMVHANDREGIST_SUCCESS',
    FAILURE: 'SEND_AISMVHANDREGIST_FAILURE',
  },
  getAisMvBySearch: {
    REQUEST : 'SEND_AISMVHANDREGISTBYSEARCH_REQUEST',
    SUCCESS : 'SEND_AISMVHANDREGISTBYSEARCH_SUCCESS',
    FAILURE : 'SEND_AISMVHANDREGISTBYSEARCH_FAILURE',
  },
  createSubscribeAisMv : {
    REQUEST: 'CREATE_SUBSCRIBE_REQUEST',
    SUCCESS: 'CREATE_SUBSCRIBE_SUCCESS',
    FAILURE: 'CREATE_SUBSCRIBE_FAILURE',
  },
  deleteSubscribeAisMv : {
    REQUEST: 'DELETE_SUBSCRIBE_REQUEST',
    SUCCESS: 'DELETE_SUBSCRIBE_SUCCESS',
    FAILURE: 'DELETE_SUBSCRIBE_FAILURE',
  },
}