import {
	MessageToAdminConstants
}from "./constans"
import notice from "../../components/Notice"

const initialState =
{
	isMessageSend          : false,
	isSending              : false,
	currentItem            : {},
	adminLookedMessageSend : false,
	sendingAnswer          : false,
	sendingToSpam          : false,
	sendingToSpamResult    : null
}

export default function (state = initialState, action){
	switch (action.type){
		case MessageToAdminConstants.GetItems.REQUEST :
			return {
				...state,
				isMessageSend : false,
				isSending     : true
			}
		case MessageToAdminConstants.GetItems.SUCCESS :
			return {
				...state,
				isSending   : false,
				currentItem : action.payload.result
			}
		case MessageToAdminConstants.GetItems.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				isSending : false,
				error     : action.payload.err.data
			}
		case MessageToAdminConstants.SetMessage.REQUEST :
			return {
				...state,
				isMessageSend : false,
				sendingAnswer : true
			}
		case MessageToAdminConstants.SetMessage.SUCCESS :
			return {
				...state,
				sendingAnswer : false,
				isMessageSend : true
			}
		case MessageToAdminConstants.SetMessage.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				sendingAnswer : false,
				isMessageSend : false,
				error         : action.payload.err.data
			}
		case MessageToAdminConstants.adminLookedMessage.REQUEST :
			return {
				...state,
				adminLookedMessageSend: true
			}
		case MessageToAdminConstants.adminLookedMessage.SUCCESS :
			const result = action.payload.result
			const newItems = [...state.currentItem.items]
			newItems[newItems.findIndex(x => x.id === result.id)] = result
			return {
				...state,
				adminLookedMessageSend : false,
				currentItem            : {
					...state.currentItem,
				 items: newItems
				}
			}
		case MessageToAdminConstants.adminLookedMessage.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				adminLookedMessageSend : false,
				error                  : action.payload.err.data
			}
		case MessageToAdminConstants.sendMessageToSpam.REQUEST :
			return {
				...state,
				sendingToSpam       : true,
				sendingToSpamResult : null
			}
		case MessageToAdminConstants.sendMessageToSpam.SUCCESS :
			return {
				...state,
				sendingToSpam       : false,
				sendingToSpamResult : action.payload.result
			}
		case MessageToAdminConstants.sendMessageToSpam.FAILURE :
			//notice("error", action.payload.err.data.errors);
			return {
				...state,
				sendingToSpam       : false,
				sendingToSpamResult : null,
				error               : action.payload.err.data
			}
		case MessageToAdminConstants.sendMessagesListToSpam.REQUEST :
			return {
				...state,
				sendingToSpam       : true,
				sendingToSpamResult : null
			}
		case MessageToAdminConstants.sendMessagesListToSpam.SUCCESS :
			return {
				...state,
				sendingToSpam       : false,
				sendingToSpamResult : action.payload.result
			}
		case MessageToAdminConstants.sendMessagesListToSpam.FAILURE :
			return {
				...state,
				sendingToSpam       : false,
				sendingToSpamResult : null,
				error               : action.payload.err.data
			}
		default :
			return state
	}
}