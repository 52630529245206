import {newsConstants} from "./constants"
import notice from "../../../components/Notice";
 
const initialState = {
  //deleteNews
  newsDeleted:false,
  //changeNews
  newsChanged:false,
  //sendNews;
  isSended: false,
  //getNews
  news: {},
  isSending: false,
  //getOneNews
  errorOneNewsEdit: null,
  oneNews:null,
  //getFile
  isFileSending:false,
  newsFile: null,
  //sendFile
  idFile:null,
  fileSended: false,
  //sendFiles
  idsFiles: [],
  filesSending: false,
  filesSended: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    //changeNews
    case newsConstants.changeNews.REQUEST:
      return {
        ...state,
        newsChanged: null,
      };
    case newsConstants.changeNews.SUCCESS:
      return {
        ...state,
        newsChanged: true,
      };
    case newsConstants.changeNews.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        newsChanged: false,
        error: action.payload.err.data,
      };
    //deleteNews
    case newsConstants.deleteNews.REQUEST:
      return {
        ...state,
        newsDeleted: null,
      };
    case newsConstants.deleteNews.SUCCESS:
      return {
        ...state,
        newsDeleted: true,
      };
    case newsConstants.deleteNews.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        newsDeleted: false,
        error: action.payload.err.data,
      };
    //createNews;
    case newsConstants.createNews.REQUEST:
      return {
        ...state,
        isSended: null,
      };
    case newsConstants.createNews.SUCCESS:
      return {
        ...state,
        isSended: true,
      };
    case newsConstants.createNews.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        isSended: false,
        error: action.payload.err.data,
      };
    //getOneNews
    case newsConstants.getOneNews.REQUEST:
      return {
        ...state,
        errorOneNewsEdit: null,
        oneNews:null //false
      };
    case newsConstants.getOneNews.SUCCESS:
    return {
        ...state,
        errorOneNewsEdit: null,
        oneNews: action.payload.result,
      };
    case newsConstants.getOneNews.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        oneNews:null,
        errorOneNewsEdit: action.payload.err.data,
      };
    //getNews
    case newsConstants.getNews.REQUEST:
      return {
        ...state,
        newsDeleted: null,
        isSending: true,
      };
    case newsConstants.getNews.SUCCESS:
      return {
        ...state,
        isSending: false,
        news: action.payload.result,
      };
    case newsConstants.getNews.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        isSending:false,
        error: action.payload.err.data,
      };
    //downloadNewsFile
    case newsConstants.downloadFile.REQUEST:
      return {
        ...state,
        isFileSending:true,
      };
    case newsConstants.downloadFile.SUCCESS:
      return {
        ...state,
        isFileSending:false,
        newsFile:action.payload.result
      };
    case newsConstants.downloadFile.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        isFileSending:false,
        error: action.payload.err.data,
      };
    //sendFile
    case newsConstants.sendFile.REQUEST:
      return {
        ...state,
        fileSended:false,
      };
    case newsConstants.sendFile.SUCCESS:
      return {
        ...state,
        fileSended:true,
        idFile: action.payload.result
      };
    case newsConstants.sendFile.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        fileSended:false,
        error: action.payload.err.data,
      };
      //sendFiles
    case newsConstants.sendFiles.REQUEST:
      return {
        ...state,
        falesSending:true,
        filesSended:false
      };
    case newsConstants.sendFiles.SUCCESS:
      return {
        ...state,
        falesSending: false,
        filesSended:true,
        idsFiles: action.payload.result
      };
    case newsConstants.sendFiles.FAILURE:
      //notice("error", action.payload.err.data.errors);
      return {
        ...state,
        filesSended:false,
        error: action.payload.err.data,
      };
    default:
      return state;
  }
}